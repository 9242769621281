/* playfair font */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
.playfair {
  font-family: "Playfair Display", serif;
}
/* roboto font */
@import url("https://fonts.googleapis.com/css2?family=Mogra&family=Outfit:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.roboto {
  font-family: "Roboto", sans-serif;
}

/* popins font */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.poppins {
  font-family: "Poppins", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.outfit {
  font-family: "Outfit", sans-serif;
}
/* .home{
    border: 1px solid transparent;
} */
.carousel-control-prev,
.carousel-control-next {
  width: 5%; /* Adjust as needed */
}

.custom-carousel-control {
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5%; /* Adjust width as needed */
  opacity: 1;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Adds background to control area for better visibility */
}

.custom-carousel-control:hover {
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Optional: Changes background color on hover */
}

.custom-carousel-control .carousel-control-prev-icon,
.custom-carousel-control .carousel-control-next-icon {
  width: 100%;
  height: 100%;
}

.home .home-slider .bg1 {
  background-image: url(../../../public/images/home/slider1.png);
  width: 100%;
  /* height: 70vh; */
  object-fit: contain;
  background-size: cover;
  /* padding-top: 30px;
    padding-bottom: 200px; */
}
.home .home-slider .bg1 .bg_a {
  padding-top: 300px;
}
.home .home-slider .bg1 .bg_a a {
  background-color: #30312d;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 20px;
}
.home .home-slider .bg1 h1 {
  padding-top: 50px;
  font-size: 53px;
  font-weight: 700;
}
.home .home-slider .bg1 p {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 400;
}
.home .home-slider .bg1 .home_get {
  margin-top: 50px;
  margin-bottom: 50px;
}
.home .home-slider .bg1 .home_get a {
  text-decoration: none;
  background-color: #085097;
  color: #fff;
  padding: 15px 25px;
  font-size: 17px;
  font-weight: 700;
  border-radius: 10px;
}
.home .home-slider .bg1 .s_bg1 {
  margin-top: 100px;
}
.home .home-slider .bg1 .s_bg1 img {
  /* max-width: 100%; */
  z-index: 99999;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.home .home-slider .bg1 .s_bg1 img {
  animation: bounce 2s infinite;
}
.home .home-slider .bg2 {
  background: linear-gradient(
      180deg,
      rgba(72, 72, 72, 0.86) -1.95%,
      rgba(11, 15, 26, 0.86) 55.33%
    ),
    url(../../../public/images/home/slider2.png);
  width: 100%;
  /* height: 70vh; */
  object-fit: contain;
  background-size: cover;
  /* padding-top: 30px; */
  padding-bottom: 60px;
}
.home .home-slider .bg2 .bg_a {
  padding-top: 300px;
}
.home .home-slider .bg2 .bg_a a {
  /* background-color: #FFDE8F; */
  color: #ffde8f;
  font-size: 15px;
  font-weight: 500;
  /* padding: 10px 20px; */
}
.home .home-slider .bg2 h1 {
  color: #fff;
  padding-top: 50px;
  font-size: 53px;
  font-weight: 700;
}
.home .home-slider .bg2 p {
  color: #fff;
  padding-top: 10px;
  font-size: 15px;
  font-weight: 400;
}
.home .home-slider .bg2 .home_get {
  margin-top: 50px;
  margin-bottom: 50px;
}
.home .home-slider .bg2 .home_get a {
  text-decoration: none;
  background-color: #085097;
  color: #fff;
  padding: 15px 25px;
  font-size: 17px;
  font-weight: 700;
  border-radius: 10px;
}
.home .home-slider .bg2 .s_bg2 {
  margin-top: 300px;
}
.home .home-slider .bg2 .s_bg2 img {
  /* max-width: 100%; */
  z-index: 99999;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.home .home-slider .bg2 .s_bg2 img:hover {
  animation: bounce 2s;
}
.home .home-slider .bg3 {
  background: url(../../../public/images/home/Slider3.png);
  width: 100%;
  object-fit: contain;
  background-size: cover;
  padding: 324px;
}
.home .home-slider .bg3 h1 {
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 53px;
}
.home .home-slider .bg3 p {
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-size: 21px;
}
.home .home-slider .bg3 p span {
  color: #28a9e2;
}

.home .home-slider .sli-1 {
  background: url(../../../public/images/home/Slider4.png);
  width: 100%;
  object-fit: contain;
  background-size: cover;
  padding-top: 350px;
  padding-bottom: 350px;
}
.home .home-slider .sli-1 h1 {
  color: #fff;
  font-weight: 700;
  font-size: 53px;
}
.home .home-slider .sli-1 p {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  margin-top: 30px;
}
.home .home-slider .sli-2 {
  background: url(../../../public/images/home/Slider3.png);
  width: 100%;
  object-fit: contain;
  background-size: cover;
  padding-top: 334px;
  padding-bottom: 330px;
}

/* Welcome seaction strat */
.welcome {
  margin-top: 80px;
}
.welcome h5 {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #1c3f97;
}
.welcome .custom-hr {
  width: 18%;
  margin: 0 auto;
}
.welcome .custom-hr hr {
  border-top: 2px solid #1c3f97;
  opacity: 1;
  margin: 0;
}
.welcome h1 {
  text-align: center;
  font-weight: 700;
  font-size: 45px;
  margin-top: 20px;
}
.welcome p {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.welcome .owe {
  margin-top: 50px;
}
.welcome .owe .box {
  padding: 20px;
  box-shadow: 0px 0px 14.1px 0px #00000040;
  height: 100%;
  border: 2px solid transparent;
  transition: 0.3s ease-in;
}
.welcome .owe .box:hover {
  border: 2px solid #085097;
}
.welcome .owe .box img {
  width: 64px;
  height: 64px;
}
.welcome .owe .box h6 {
  color: #0a5096;
  font-size: 23px;
  font-weight: 700;
  margin-top: 15px;
}
.welcome .owe .box p {
  font-weight: 400;
  font-size: 15px;
  text-align: start;
  margin-top: 10px;
}
/* .welcome .img-container {
    background-color: rgba(33, 144, 188, 1);
    padding: 24px;
}
  
.welcome .sec-container {
    background-color: rgba(11, 79, 150, 1);
    padding: 27px;
}
.welcome .main-div {
    display: flex;
    flex-direction: column;

    padding-top: 60px;
    padding-bottom: 60px;
    gap: 18px;
}
.welcome .main-div .para-div {
    color: rgba(28, 63, 151, 1);
    font-size: 18px;
    font-weight: 700;
}
  
.welcome .main-div .inner-div h4 {
    font-size: 35px;
    font-weight: 700;
}
.welcome .main-div p{
    font-size: 17px;
    font-weight: 400;
}
  
.welcome .main-div .main-img {
    display: flex;
    flex-direction: row;
    gap: 24px;
}
  
.welcome .main-div .main-img p {
    font-size: 15px;
    font-weight: 400;
}
  
.welcome .main-img h5 {
    font-size: 1.2rem;
    color: rgba(28, 63, 151, 1);
    font-weight: 600;
}
  
.welcome .main-img-sec {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    justify-content: center;
}
  
.welcome .main-img-sec p {
    font-size: 15px;
    font-weight: 400;
}
  
.welcome .main-img-sec h5 {
    font-size: 1.2rem;
    color: rgba(33, 144, 188, 1);
    font-weight: 700;
}
  
.welcome .desc-bottom {
    padding-top: 8px;
}
  
.welcome .first-container {
    padding-top: 8px;
}
  
.welcome .main-div button {
    background-color: white;
    border: solid 1.8px rgba(11, 79, 150, 1);
    border-radius: 10px;
    color: rgba(11, 79, 150, 1);
    width: fit-content;
    padding: 8px 14px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: 1s ease;
}
  
.welcome .main-div button:hover {
    background-color: rgba(11, 79, 150, 1);
    color: white;
    border: solid 1.8px transparent;
}
.welcome .sec-div img{
    max-width: 100%;
} */
/* Welcome seaction end */

/* own seaction start */

/* .first-row {
    background-color: rgba(230, 230, 230, 255);
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }
  
  .first-row .first-sec {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding-right: 16px;
    justify-content: center;
    align-items: center;
  }
  
  .first-row .first-sec h5 {
    color: rgba(10, 80, 150, 1);
    font-weight: 700;
  }

  .first-row .first-sec p {
    font-size: 0.8rem;
    padding-bottom: 0px;
    margin-bottom: 0px;
  } */

/* own seaction end */

/* modern seaction start */

/* .main-div-sec {
    background-color: rgba(11, 79, 150, 1);
    color: white;
    padding-bottom: 44px;
  }
  
  .main-div-sec .heading-tag h5 {
    width: 60%;
    margin: 0 auto;
    padding: 54px;
    align-items: center;
    text-align: center;
  }
  
  .main-div-sec .first-wind {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 12px;
  }
  
  .main-div-sec .first-wind img {
    width: 66px;
    object-fit: cover;
    padding-bottom: 12px;
  }
  
  .main-div-sec .first-wind p {
    font-size: 1.1rem;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  } */

/* modern seaction end */

/* home about seaction start */
.home-about {
  margin-top: 80px;
}
.home-about .ab-img-h img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.home-about .h-about-text h5 {
  /* text-align: center; */
  color: #1c3f97;
  font-size: 18px;
  font-weight: 700;
}
.home-about .h-about-text .custom-hr {
  width: 13%;
  /* margin: 0 auto;
    text-align: center; */
}
.home-about .h-about-text hr {
  border-top: 2px solid #1c3f97;
  opacity: 1;
  margin: 0;
}
.home-about .h-about-text h2 {
  margin-top: 20px;
  font-size: 30px;
  font-weight: 700;
}
.home-about .h-about-text h2 span {
  color: #1c3f97;
}
.home-about .h-about-text p {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
}
.home-about .h-about-text .years {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.home-about .h-about-text .years .y-img img {
  background-color: #2190bc;
  padding: 20px;
}
.home-about .h-about-text .years .y-text h4 {
  color: #1c3f97;
  font-weight: 700;
  font-size: 21px;
}
.home-about .h-about-text .years .y-text h6 {
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
}
.home-about .h-about-text .years .t-img img {
  background-color: #0b4f96;
  padding: 23px;
}
.home-about .h-about-text .years .t-text h4 {
  color: #2190bc;
  font-weight: 700;
  font-size: 21px;
}
.home-about .h-about-text .years .t-text h6 {
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
}

/* .h-about-text .faq .cont-faq {
    background-color: #0B4F960D;
    color: black;
    box-shadow: 0 5px 10px 0 rgb(0,0,0,0.25);
    margin: 20px 0;
}
  
.h-about-text .faq .question {
    font-size: 19px;
    font-weight: 500;
    padding: 20px 80px 20px 20px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #4D4D4D;
}
.h-about-text .faq .question.active {
    background-color: #1C3F97;
    color: white;
    transition: .2s ease-in;
}

.h-about-text .faq .question.active::after {
    color: white;
}
.h-about-text .faq .question::after {
    content: "\002B";
    font-size: 2.2rem;
    position: absolute;
    right: 20px;
    transition: 0.2s;
    background-color: #1C3F97;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
}
  
.h-about-text .faq .question.active::after {
    transform: rotate(45deg);
}
  
.h-about-text .faq .answercont {
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
}
  
.h-about-text .faq .answer {
    padding: 10px 20px 20px;
    line-height: 1.5rem;
    font-size: 17px;
    font-weight: 400;
} */

/* home about seaction end */

/* Window wood seaction start */
.window-wood {
  margin-top: 50px;
}
.window-wood .box {
  padding: 30px;
  box-shadow: 0px 0px 29.8px 0px #00000040;
  height: 100%;
  border: 2px solid transparent;
}
.window-wood .box:hover {
  border: 2px solid #085097;
  transition: 0.3s ease-in;
}
.window-wood .box .box-text {
  display: flex;
  gap: 20px;
}
.window-wood .box .box-text .txet h4 {
  font-size: 25px;
  font-weight: 700;
  color: #2190bc;
}
.window-wood .box .box-text .txet h4 span {
  color: #085196;
}
.window-wood .box .box-text .txet p {
  font-weight: 400;
  font-size: 14px;
}
.window-wood .box .box-text .txet a {
  font-weight: 700;
  font-size: 12px;
  text-decoration: none;
  color: #2190bc;
}
.window-wood .box .box-text .box-img img {
  width: 200px;
}
.window-wood .box:hover img {
  transform: scale(1.1);
  transition: 0.5s ease-in;
}

/* Window wood seaction end */

/* modern seaction start */
.modern-se {
  margin-top: 80px;
  padding: 20px;
  background-color: #0b4f96;
  color: #fff;
}
.modern-se h4 {
  width: 60%;
  text-align: center;
  margin: auto;
  font-weight: 700;
  font-size: 25px;
  margin-top: 20px;
}
.modern-se .pr-windo {
  margin-top: 50px;
}
.modern-se .pr-windo img {
  display: block;
  margin: auto;
  width: 100px;
  height: 100px;
  transition: 0.3s ease-in;
}
.modern-se .pr-windo .wi-pr:hover img {
  transform: rotateY(180deg);
}
.modern-se .pr-windo p {
  padding-top: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 25px;
  /* width: 70%; */
  margin: 0 auto;
}
.modern-se .much {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
}
.modern-se .much a {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

/* our window seaction start */
.our-window {
  margin-top: 80px;
}
.our-window h5 {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  color: #1c3f97;
}
.our-window .custom-hr {
  width: 10%;
  margin: 0 auto;
  text-align: center;
}
.our-window hr {
  border-top: 2px solid #1c3f97;
  opacity: 1;
  margin: 0;
}
.our-window h1 {
  text-align: center;
  font-weight: 700;
  font-size: 50px;
  margin-top: 30px;
}
.our-window p {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  margin-top: 10px;
}
.our-window .window_product {
  margin-top: 50px;
}
.our-window .window_product a {
  text-decoration: none;
  color: black;
}
.our-window .window_product .wi-pr-img .windo-sli img {
  /* max-width: 0%; */
  width: 389px;
  height: auto;
  border-radius: 10px 10px 0 0;
}
/* .our-window .window_product .case {
  display: flex;
  align-items: center;
}
.our-window .window_product .case img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
} */
.our-window .window_product .card{
  border: none;
}
.our-window .window_product .card .card-body img {
  width: 70px;
  height: 70px;
  margin-right: 10px;
}
.our-window .window_product .card .card-body  {
  align-items: center;
  box-shadow: 0px 0px 16.1px 0px #00000040;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 18px;

}
.our-window .window_product .card .card-body h6 {
  align-items: center;
  font-weight: 500;
  font-size: 18px;
}
/* .our-window .box{
    text-align: center;
    margin-top: 20px;
}
.our-window .box img{
    width: 275px;
    height: 275px;
    max-width: 100% !important;
}
.our-window .box h3{
    color: #085199;
    font-size: 30px;
    font-weight: 700;
}
.our-window .box p{
    color: #505050;
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
}
.our-window .box .read{
    margin-top: 30px;
}
.our-window .box .read a{
    text-decoration: none;
    background-color: #085199;
    color: #fff;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 600;
} */
/* our window seaction end */
.make {
  margin-top: 80px;
}
.make h1 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  width: 60%;
  margin: 0 auto;
}
.make h1 span {
  color: #1c3f97;
}
.make .make-img {
  margin-top: 50px;
}
.make .make-img img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
/* our window seaction start */

.more-home {
  margin-top: 50px;
  background-color: #e3ebf3;
  padding: 60px;
}
.more-home h3 {
  margin-top: 50px;
  font-size: 46px;
  font-weight: 700;
}
.more-home h1 {
  color: #1c3f97;
  font-size: 75px;
  font-weight: 700;
}
.more-home p {
  font-size: 20px;
  font-weight: 400;
  margin-top: 30px;
}
.more-home .more-img-home img {
  max-width: 100%;
}
.more-home .box {
  margin-top: 50px;
  text-align: center;
}
.more-home .box .round {
  background-color: white;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin: 0 auto;
}
.more-home .box h5 {
  margin-top: 20px;
  color: #1c3f97;
  font-weight: 800;
  font-size: 21px;
}
/* our window seaction end */

/* blog seaction start */
.home-blog {
  margin-top: 80px;
}
.home-blog .main h5 {
  text-align: center;
  color: #3a769d;
  font-weight: 500;
  font-size: 27px;
}
.home-blog .main h3 {
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  color: #26324e;
}
.home-blog .custom-hr {
  width: 20%;
  margin: 0 auto;
  text-align: center;
}
.home-blog .custom-hr hr {
  border-top: 4px solid #3a769d;
  opacity: 1;
  margin: 0;
}
.home-blog .blog-seac {
  margin-top: 80px;
}
.home-blog .blog-seac .card {
  border: none;
}
.home-blog .blog-seac .card img {
  max-width: 100%;
  height: 259px;
}
.home-blog .blog-seac .card .card-body {
  box-shadow: 0px 0px 22.9px 0px #00000040;
}
.home-blog .blog-seac .card .card-body h5 {
  color: #0a5096;
  font-size: 20px;
  font-weight: 700;
}
.home-blog .blog-seac .card .card-body p {
  font-size: 16px;
  font-weight: 300;
}
.home-blog .blog-seac .card .card-body h6 {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
.home-blog .blog-seac .card .card-body .more {
  margin-top: 10px;
}
.home-blog .blog-seac .card .card-body .more a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 800;
  color: #0a5096;
}

/* .home-blog .b-deatiles{
    margin-top: 50px;
}
.home-blog .box{
    padding: 20px;
    box-shadow: 2px 2px 14.6px 0px #00000040;

}
.home-blog .box .sm{
    display: flex;
    gap: 20px;
}
.home-blog .box .sm p{
    color: #1C3F97;
    font-size: 16px;
    font-weight: 300;
}
.home-blog .box .sm h5{
    font-size: 17px;
    font-weight: 600;
}
.home-blog .blog-bg1{
    background: linear-gradient(180deg, rgba(38, 50, 78, 0) -2.18%, rgba(38, 50, 78, 0.98) 100%) ,url(/public/images/home/blog-1.png);
    width: 100%;
    padding: 30px;
    background-repeat: no-repeat;
    color: #fff;
}

.home-blog .blog-bg1 .indi{
    padding-top: 30px;
}
.home-blog .blog-bg1 .indi a{
    text-decoration: none;
    color: #fff;
    background-color: #1C3F97;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 19px;
}
.home-blog .blog-bg1 h5{
    font-weight: 600;
    font-size: 19px;
    padding-top: 225px;
}
.home-blog .blog-bg1 .by{
    display: flex;
    justify-content: space-between;
    color: #7CDAFF;
}
.home-blog .blog-bg2{
    background: linear-gradient(180deg, rgba(38, 50, 78, 0) -2.18%, rgba(38, 50, 78, 0.98) 100%) ,url(/public/images/home/blog-2.png);
    width: 100%;
    padding: 30px;
    background-repeat: no-repeat;
    color: #fff;
}
.home-blog .blog-bg2 .indi{
    padding-top: 30px;
}
.home-blog .blog-bg2 .indi a{
    text-decoration: none;
    color: #fff;
    background-color: #1C3F97;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 19px;
}
.home-blog .blog-bg2 h5{
    font-weight: 600;
    font-size: 19px;
    padding-top: 225px;
}
.home-blog .blog-bg2 .by{
    display: flex;
    justify-content: space-between;
    color: #7CDAFF;
} */
/* blog seaction end */

/* video seaction start */
.home-video {
  margin-top: 100px;
}
.home-video video {
  width: 100%;
  height: auto;
}
.home-video h1 {
  font-weight: 700;
  font-size: 37px;
  padding-left: 20px;
  border-left: 9px solid #085097;
  margin-top: 10px;
}
.home-video h1 span {
  color: #085199;
}
.home-video p {
  margin-top: 30px;
  font-weight: 400;
  font-size: 19px;
}
.home-video .box {
  background-color: #e4e4e4;
  padding: 20px;
  margin-top: 30px;
}
.home-video .box .coun h2 {
  text-align: center;
  color: #0b4f96;
  font-size: 35px;
  font-weight: 700;
}
.home-video .box .coun h6 {
  text-align: center;
  color: #0b4f96;
  font-size: 15px;
  font-weight: 800;
}

/* client seaction start */
.client {
  margin-top: 80px;
  margin-bottom: 80px;
}
.client h5 {
  text-align: center;
  color: #085199;
  font-size: 21px;
  font-weight: 700;
}
.client .custom-hr {
  width: 17%;
  margin: 0 auto;
  text-align: center;
}
.client hr {
  border-top: 2px solid #085199;
  opacity: 1;
  margin: 0;
}
.client h1 {
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  margin-top: 20px;
}
.client h1 span {
  color: #085199;
}
