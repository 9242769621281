@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

.poppins {
  font-family: "Poppins", sans-serif;
}

.playfairdisplay {
  font-family: "Playfair Display", serif;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* button {
  background-color: rgba(8, 80, 151, 1);
  color: white;
  border: none;
  padding: 8px;
} */
