/* playfair font */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
.playfair {
    font-family: "Playfair Display", serif;
}
/* roboto font */
@import url('https://fonts.googleapis.com/css2?family=Mogra&family=Outfit:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.roboto{
    font-family: "Roboto", sans-serif;
}

/* popins font */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.poppins{
    font-family: "Poppins", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.outfit {
    font-family: "Outfit", sans-serif;
}


.contact-page{
    background-image: url(../../../public/images/contact/contact-bg.png);
    width: 100%;
    object-fit: contain;
    background-size: cover;
    /* padding-top: 30px; */
    padding-bottom: 200px;
}
.contact-page h1{
    padding-top: 300px;
    font-size: 80px;
    font-weight: 700;
    text-align: center;
    color: #fff;
}

/* contact details seaction start */
.contact-main{
    margin-top: 100px;
}
.contact-main h5{
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #1C3F97;
}
.contact-main .custom-hr{
    width: 8%;
    margin: 0 auto;
    text-align: center;
}
.contact-main .custom-hr hr{
    border-top: 2px solid #1C3F97;
    opacity: 1;
    margin: 0;
}
.contact-main h1{
    text-align: center;
    font-weight: 700;
    font-size: 47px;
    margin-top: 30px;
}
.contact-main .add-de .c-box{
    display: flex;
    gap: 20px;
    margin-top: 50px;
}
.contact-main .add-de .c-box .loca{
    width: 110px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    background-color: #0B4F96;
    color: white;
    font-size: 25px;
}
.contact-main .add-de .c-box .icon{
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    background-color: #0B4F96;
    color: white;
    font-size: 25px;
}
.contact-main .add-de .c-box .our-add h6{
    font-size: 17px;
    font-weight: 500;
    color: #1C3F97;
}
.contact-main .add-de .c-box .our-add p{
    font-weight: 400;
    font-size: 14px;
    color: #505050;
    margin-bottom: 0px;
}
.contact-main .add-de .c-box .our-add p a{
    text-decoration: none;
    color: #505050;
}
/* contact details seaction end */

/* conatct map seaction start */
.contact-map{
    /* position: relative; */
    margin-top: 100px;
    margin-bottom: 50px;
}
.contact-map .contact-get .box {
    /* position: absolute; */
    display: flex;
    background-color: #fff;
    box-shadow: 0px 0px 21.6px 0px #00000040;
    /* padding: 0px 10px 0px 0px; */
    width: 1000px;
    /* width: auto; */
    margin: 0 auto;
    gap: 30px;
    margin-top: -20%;
   
    transform: translateX(0%);
    z-index: 10; 
}
.contact-map .contact-get .write{
    background-color: #0B4F96;
    color: #fff;
    padding: 30px;
    width: 300px;
}
.contact-map .contact-get .write h5{
    color: #7CDAFF;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 2px;
}
.contact-map .contact-get .write .custom-hr{
    width: 60%;
    /* margin: 0 auto;
    text-align: center; */
}
.contact-map .contact-get .write .custom-hr hr{
    border-top: 1px solid #7CDAFF;
    opacity: 1;
    margin: 0;
}
.contact-map .contact-get .write h3{
    font-weight: 600;
    font-size: 32px;
    margin-top: 20px;
}
.contact-map .contact-get .write p{
    font-weight: 300;
    font-size: 14px;
}
.contact-map .contact-get .write .icon{
    margin-top: 100px;
}
.contact-map .contact-get .write .icon i{
    background-color: white;
    color: #1C3F97;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-right: 20px;
    border-radius: 50%;

}
.contact-map .contact-get .book{
    padding: 10px;
}
.contact-map .contact-get .book h6{
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    margin-top: 30px;
}
.contact-map .contact-get .book .custom-hr{
    width: 90%;
    margin: 0 auto;
    text-align: center;
}
.contact-map .contact-get .book .custom-hr hr{
    border-top: 1px solid #2F2F2F;
    opacity: 1;
    margin: 0;
}
.contact-map .contact-get .book form{
    margin-top: 40px;
}
.contact-map .contact-get .book form .form-control{
    padding: 25px;
    border: 1px solid #989898;
}
.contact-map .contact-get .book form button{
    width: 100%;
    background-color: #0B4F96;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px;
    font-size: 19px;
    font-weight: 500;
}
/* conatct map seaction end */

/* news latter section start */
.news-lat{
    margin-bottom: 80px;
}
.news-lat .latter{
    padding: 10px 150px 0px;
}
.news-lat .latter h5{
    color: #1C3F97;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 2px;
}
.news-lat .latter .custom-hr{
    width: 13%;
}
.news-lat .latter .custom-hr hr{
    border-top: 1px solid #1C3F97;
    opacity: 1;
    margin: 0;
}
.news-lat .latter h4{
    font-weight: 600;
    font-size: 31px;
}
.news-lat .latter .form-control{
    position: relative;
    padding: 25px;
    border: none;
    box-shadow: 0px 0px 18.5px 0px #00000040;
}
.news-lat .latter button{
    position: absolute;
    top: 5px;
    right: 20px;
    background-color: #0B4F96;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    border: none;
    padding: 8px 15px;
}
@media screen and (max-width: 1399px) and (min-width: 1200px){
    .contact-page{
        padding-bottom: 180px;
    }
    .contact-page h1{
        padding-top: 250px;
        font-size: 70px;
    }
    .contact-main .add-de .c-box .loca{
        width: 140px;
    }
}
@media screen and (max-width: 1199px) and (min-width: 1023px){
    .news-lat .latter h4{
        font-size: 25px;
    }
    .contact-main .add-de .c-box .loca{
        width: 150px;
    }
    .contact-map .contact-get .box{
        width: 979px;
    }
}
@media screen and (max-width: 1024px) and (min-width: 992px){
    .news-lat .latter {
        padding: 0px 0px 0px;
    }
    .contact-map .contact-get .box{
        width: 900px;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px){
    .abc .header{
        top: 0;
    }
    .contact-page{
        padding-bottom: 180px;
    }
    .contact-page h1{
        padding-top: 250px;
        font-size: 70px;
    }
    .contact-main {
        margin-top: 70px;
    }
    .contact-main .add-de .c-box .loca{
        width: 135px;
    }
    .add-de .col-lg-4{
        width: 50%;
    }
    .contact-map .contact-get .box{
        width: 700px;
    }
    .contact-map .contact-get .book .col-lg-6{
        width: 50%;
    }
    .contact-map .contact-get .write{
        padding: 17px;
    }
    .news-lat{
        margin-top: 0px;
    }
    .news-lat .latter{
        padding: 0;
    }
    .news-lat .latter .col-lg-6{
        width: 50%;
    }
    .news-lat .latter h4{
        font-size: 26px;
    }
}
@media screen and (max-width: 528px) and (min-width: 426px){
    .contact-main .add-de .c-box .loca{
        width: 115px !important;
    }
}
@media screen and (max-width: 767px) and (min-width: 426px){
    .abc .header{
        top: 0;
    }
    .contact-page{
        padding-bottom: 150px;
    }
    .contact-page h1{
        padding-top: 200px;
        font-size: 60px;
    }
    .contact-main {
        margin-top: 50px;
    }
    .contact-main .add-de .c-box .loca{
        width: 86px;
    }
    /* .add-de .col-lg-4{
        width: 50%;
    } */
    .contact-map .contact-get .box{
        display: block;
        width: auto;
        padding: 10px;
    }
    .contact-map .contact-get .box .write{
        display: none;
    }
    .contact-map .contact-get .book .col-lg-6{
        width: 50%;
    }
    .contact-map .contact-get .write{
        padding: 17px;
    }
    .news-lat{
        margin-top: 0px;
    }
    .news-lat .latter{
        padding: 0;
    }
    
    .news-lat .latter h4{
        font-size: 26px;
    }
}

@media screen and (max-width: 425px) and (min-width: 376px){
    .abc .header{
        top: 0;
    }
    .contact-page{
        padding-bottom: 100px;
    }
    .contact-page h1{
        padding-top: 150px;
        font-size:45px;
    }
    .contact-main {
        margin-top: 50px;
    }
    .contact-main h1 {
        font-size: 40px;
        margin-top: 10px;
    }
    .contact-main .add-de .c-box{
        margin-top: 25px;
    }
    .contact-main .add-de .c-box .loca{
        width: 120px;
    }

    .contact-map .contact-get .box{
        display: block;
        width: auto;
        padding: 10px;
        margin-top: -25%;
    }
    .contact-map .contact-get .box .write{
        display: none;
    }
    .contact-map .contact-get .book .col-lg-6{
        width: 50%;
    }
    .contact-map .contact-get .write{
        padding: 17px;
    }
    .news-lat{
        margin-top: 0px;
    }
    .news-lat .latter{
        padding: 0;
    }
    
    .news-lat .latter h4{
        font-size: 26px;
    }
}

@media screen and (max-width: 375px) and (min-width: 300px){
    .abc .header{
        top: 0;
    }
    .contact-page{
        padding-bottom: 100px;
    }
    .contact-page h1{
        padding-top: 150px;
        font-size:45px;
    }
    .contact-main {
        margin-top: 50px;
    }
    .contact-main h1 {
        font-size: 40px;
        margin-top: 10px;
    }
    .contact-main .add-de .c-box{
        margin-top: 25px;
    }
    .contact-main .add-de .c-box .loca{
        width: 140px;
    }

    .contact-map .contact-get .box{
        display: block;
        width: auto;
        padding: 10px;
        margin-top: -25%;
    }
    .contact-map .contact-get .box .write{
        display: none;
    }
    .contact-map .contact-get .book .col-lg-6{
        width: 50%;
    }
    .contact-map .contact-get .write{
        padding: 17px;
    }
    .news-lat{
        margin-top: 0px;
    }
    .news-lat .latter{
        padding: 0;
    }
    
    .news-lat .latter h4{
        font-size: 26px;
    }
}
@media (max-width: 320px){
    .contact-main .add-de .c-box .loca{
        width: 175px;
    }
}