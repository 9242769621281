/* playfair font */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
.playfair {
    font-family: "Playfair Display", serif;
}
/* roboto font */
@import url('https://fonts.googleapis.com/css2?family=Mogra&family=Outfit:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.roboto{
    font-family: "Roboto", sans-serif;
}

/* popins font */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.poppins{
    font-family: "Poppins", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.outfit {
    font-family: "Outfit", sans-serif;
}


.product-details-page{
    background-image: url(../../../../public/images/productdetails/bg.png);
    width: 100%;
    object-fit: contain;
    background-size: cover;
    /* padding-top: 30px; */
    padding-bottom: 200px;
}
.product-details-page h1{
    padding-top: 300px;
    font-size: 66px;
    font-weight: 700;
    text-align: center;
    color: #fff;

}
.product-details-page .show_page{
    text-align: center;
}
.product-details-page .show_page a{
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    /* text-align: center; */
}
.product-details-page .show_page i{
    color: #fff;
}


.cash-me-windo{
    margin-top: 80px;
}
.cash-me-windo h2{
    font-size: 45px;
    font-weight: 700;
}
.cash-me-windo p{
    font-weight: 400;
    font-size: 19px;
}
.cash-me-windo .main-details-img img{
    max-width: 100%;
    margin-top: 50px;
}
.cash-me-windo .features{
    margin-top: 50px;
}
.cash-me-windo .features .wi-text{
    display: flex;
    gap: 20px;
    margin-top: 10px;
}
.cash-me-windo .features .wi-text .wi-img{
    border: 1px solid #0A5096;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;

}
.cash-me-windo .features .wi-text .wi-img img{
    height: 40px;
    width: 40px;
}
.cash-me-windo .features .wi-text .wi-te h6{
    margin-top: 12px;
    font-size: 15px;
    font-weight: 400;
}
.cash-me-windo .full-details img{
    max-width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 40px;
}





.cash-me-windo .investa {
    margin-top: 80px;
}
.cash-me-windo .investa h2{
    font-weight: 500;
    font-size: 35px;
}
.cash-me-windo .investa p{
    font-weight: 400;
    font-size: 20px;
}
.cash-me-windo .investa h4{
    font-weight: 500;
    font-size: 28px;
    margin-top: 30px;
}
.cash-me-windo .investa li{
    font-weight: 400;
    font-size: 19px;
    margin-top: 10px;
}
.cash-me-windo .investa .table_show{
    margin-top: 30px;
}
.cash-me-windo .investa .table_show table, th, td {
    border: solid 1px #8E8E8E;
    /* padding: 10px; */
}
/* .table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.table {
    width: 100%;
    max-width: 100%;
    height: auto;
} */
.cash-me-windo .catagroy_window a{
    text-decoration: none;
    color: black;
}
.cash-me-windo .catagroy_window{
    margin-top: 80px;
}
.cash-me-windo .catagroy_window h2{
    font-size: 35px;
    font-weight: 600;
}
.cash-me-windo .catagroy_window .col-lg-3{
    margin-top: 20px;
}
.cash-me-windo .catagroy_window .box{
    border: 1px solid black;
    margin-top: 30px;
    height: 100%;
}
.cash-me-windo .catagroy_window .box .box-img{
    background-color: #D9D9D9;
}
.cash-me-windo .catagroy_window .box .box-img img{
    display: block;
    margin: 0 auto;
    padding: 40px;
}
.cash-me-windo .catagroy_window .box h3{
    margin-left: 20px;
    margin-top: 20px;
}
.cash-me-windo .catagroy_window .box hr{
    opacity: 1;
    margin: 0;
}
.cash-me-windo .catagroy_window .box .box-tet{
    padding: 20px;
}
.cash-me-windo .catagroy_window .box .box-tet p{
    font-size: 15px;
    text-align: justify;
}
.cash-me-windo .catagroy_window .box .box-tet a{
    text-decoration: none;
    color: #0A5096;
    font-weight: 600;
    font-size: 16px;
}



.cash-me-windo .system_img{
    margin-top: 80px;
}
.cash-me-windo .system_img h3{
    font-size: 23px;
    font-weight: 400;
}

.cash-me-windo .system_img img{
    padding-top: 20px;
    /* display: block;
    margin: 0 auto; */
    max-width: 100%;
}




.cash-me-windo .img-gallery{
    margin-top: 80px;
}
.cash-me-windo .img-gallery h3{
    font-weight: 600;
    font-size: 35px;
}
.cash-me-windo .img-gallery img{
    max-width: 95%;
    display: block;
    margin: 0 auto;
    width: 296px;
    height: 256px;
    object-fit: cover;
    border-radius: 7px;
}
.cash-me-windo .faq-sec{
    margin-top: 50px;
}
.cash-me-windo .faq-sec h3{
    font-weight: 600;
    font-size: 35px;
}
.cash-me-windo .faq-sec .faq .cont-faq {
    background-color: #0B4F960D;
    color: black;
    box-shadow: 0 5px 10px 0 rgb(0,0,0,0.25);
    margin: 20px 0;
}
  
.cash-me-windo .faq-sec .faq .question {
    font-size: 19px;
    font-weight: 500;
    padding: 20px 80px 20px 20px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #4D4D4D;
}
.cash-me-windo .faq-sec .faq .question.active {
    background-color: #1C3F97;
    color: white;
    transition: .2s ease-in;
}

.cash-me-windo .faq-sec .faq .question.active::after {
    color: white;
}
.cash-me-windo .faq-sec .faq .question::after {
    content: "\002B";
    font-size: 2.2rem;
    position: absolute;
    right: 20px;
    transition: 0.2s;
    background-color: #1C3F97;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
}
  
.cash-me-windo .faq-sec .faq .question.active::after {
    transform: rotate(45deg);
}
  
.cash-me-windo .faq-sec.faq .answercont {
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
}
  
.cash-me-windo .faq-sec .faq .answer {
    padding: 10px 20px 20px;
    line-height: 1.5rem;
    font-size: 17px;
    font-weight: 400;
}
    
/* twinsh details page css */

.cash-me-windo .twinsh .twinsh-txet{
    margin-top: 50px;
}
.cash-me-windo .twinsh .twinsh-txet h3{
    font-weight: 500;
    font-size: 30px;
}
.cash-me-windo .twinsh .twinsh-txet h4{
    font-weight: 500;
    font-size: 30px;
    margin-top: 40px;
}
.cash-me-windo .twinsh .twinsh-txet p{
    font-weight: 400;
    font-size: 18px;
}

/* .p-detail{
    margin-top: 80px;
}

.p-detail .lt .box{
    padding: 20px;
    box-shadow: 0px 0px 22.3px 0px #00000040;
}
.p-detail .lt .box h3{
    color: #0A5096;
    font-size: 31px;
    font-weight: 700;
}
.p-detail .lt .box ul{
    padding-left: 0px;
}
.p-detail .lt .box ul li{
    list-style: none;
    
}
.p-detail .lt .box .nav-link {
    position: relative;
    display: flex; 
    align-items: center; 
    border: 1px solid #6F6F6F;
    margin-top: 10px;
    padding: 15px;
    width: 100%;
    text-align: start;
    border-radius: 0;
    font-weight: 600;
    font-size: 20px;
    padding-left: 30px;
}

.p-detail .lt .box .nav-link i {
    margin-left: auto; 
    color: #fff;
}

.p-detail .lt .box .nav-link::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    background-color: #fff;
    margin-left: 10px; 
}

.p-detail .lt .box .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #0A5096;
    font-weight: 700;
}
.p-detail .lt .box2{
    margin-top: 50px;
    padding: 20px;
    box-shadow: 0px 0px 22.3px 0px #00000040;
}
.p-detail .lt .box2 .info{
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
}
.p-detail .lt .box2 .info a{
    text-decoration: none;
    padding: 10px 20px;
    background-color: #0A5096;
    color: #fff;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 700;
}
.p-detail .lt .box2 h6{
    font-size: 22px;
    font-weight: 600;
    margin-top: 40px;
}
.p-detail .lt .box2 .icon {
    margin-top: 20px;
}
.p-detail .lt .box2 .icon i{
    background-color: #474747;
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 20px;
    border-radius: 50%;
    font-size: 20px;
}
.p-detail .main-detail {
    margin-left: 30px;
}
.p-detail .main-detail .pd a{
    text-decoration: none;
    color: black;
    font-size: 15px;
    font-weight: 400;
}
.p-detail .main-detail h3{
    color: #1C3F97;
    font-weight: 700;
    font-size: 25px;
    margin-top: 40px;
}
.p-detail .main-detail h4{
    color: #252525;
    font-weight: 400;
    font-size: 25px;
}
.p-detail .main-detail .custom-hr{
    width: 35%;
}
.p-detail .main-detail .custom-hr hr{
    margin: 0;
}
.p-detail .main-detail h1{
    font-weight: 700;
    font-size: 55px;
    margin-top: 20px;
    color: black;
}
.p-detail .main-detail .product-desc{
    margin-top: 30px;
}
.p-detail .main-detail .product-desc .de-img img{
    max-width: 100%;
    width: 404px;
    height: 404px;
    object-fit: contain;
}
.p-detail .main-detail .product-desc h2{
    font-weight: 500;
    font-size: 30px;
}
.p-detail .main-detail .product-desc p{
    font-weight: 400;
    font-size: 18px;
    margin-top: 20px;
}
.p-detail .main-detail .product-desc h5{
    font-weight: 500;
    font-size: 21px;
    margin-top: 20px;
}
.p-detail .main-detail .product-desc .colo{
    display: flex;
    gap: 20px;
}
.p-detail .main-detail .product-desc .colo li{
    list-style: none;
}
.p-detail .main-detail .product-desc .nav-link {
   border: none;
}
.p-detail .main-detail .product-desc .nav-tabs {
    --bs-nav-tabs-border-color: white
}
.p-detail .main-detail .product-desc ul{
    padding: 0;
}
.p-detail .main-detail .product-desc .colo .gray{
    background-color: #A4B2B3;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.p-detail .main-detail .product-desc .colo .black{
    background-color: #000;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.p-detail .main-detail .product-desc .colo .silver{
    background-color: #4D4A4A;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.p-detail .main-detail .over h3{
    color: #252525;
    font-size: 30px;
    font-weight: 500;
}
.p-detail .main-detail .over .custom-hr{
    width: 15%;
}
.p-detail .main-detail .over .custom-hr hr{
    margin: 0;
    border-top: 2px solid #1C3F97;
    opacity: 1;
}
.p-detail .main-detail .over hr{
    margin: 0;
}
.p-detail .main-detail .over .featu{
    color: #3E3E3E;
    margin-top: 50px;
}
.p-detail .main-detail .over .featu h5{
    font-size: 21px;
    font-weight: 500;
}
.p-detail .main-detail .over .featu .custom-hr{
    width: 19%;
}
.p-detail .main-detail .over .featu .custom-hr hr{
    margin: 0;
    border-top: 1px solid #3E3E3E;
    opacity: 1;
}
.p-detail .main-detail .over .featu ul{
    margin-top: 30px;
}
.p-detail .main-detail .over .opt .custom-hr{
    width: 9%;
}
.p-detail .main-detail .over .opt .custom-hr hr{
    margin: 0;
    border-top: 1px solid #3E3E3E;
    opacity: 1;
}
.p-detail .main-detail .desi-fea{
    margin-top: 50px;
}
.p-detail .main-detail .desi-fea h3{
    font-size: 30px;
    font-weight: 500;
    color: #000;
}
.p-detail .main-detail .desi-fea .vactor{
    display: flex;
    gap: 20px;
    margin-top: 20px;
}
.p-detail .main-detail .desi-fea .vactor p{
    margin-top: 0;
    font-size: 18px;
    font-weight: 400;
} */


/* related product section strat */
/* .detail-related{
    margin-top: 80px;
}
.detail-related h1{
    font-size: 56px;
    font-weight: 700;
}
.detail-related .box{
    padding: 20px;
    background-color: #EDECEC;
    text-align: center;
}
.detail-related .box img{
    width: 242px;
    height: 242px;
    max-width: 100%;
    object-fit: contain;
}
.detail-related h4{
    font-size: 26px;
    font-weight: 600;
    margin-top: 10px;
}
.detail-related a{
    text-decoration: none;
    color: #1C3F97;
    font-size: 13px;
    font-weight: 800;
} */

@media screen and (max-width: 1024px) and (min-width: 992px){
    .product-details-page h1 {
        padding-top: 250px;
        font-size: 66px;
    }
    .product-details-page{
        padding-bottom: 150px;
    }
    .cash-me-windo p{
        font-size: 18px;
    }
    .cash-me-windo .features .wi-text .wi-te h6{
        margin-top: 5px;
    }

    .cash-me-windo .twinsh .twinsh-txet h3{
        font-size: 26px;
    }
    .cash-me-windo .twinsh .twinsh-txet h4{
        font-size: 26px;
        margin-top: 0px;
    }
    .cash-me-windo .twinsh .twinsh-txet p{
        font-size: 15px;
    }
    /* .p-detail .lt .box h3 {
        font-size: 23px;
    }
    .p-detail .lt .box .nav-link {
        font-size: 16px;
        padding-left: 20px;
    }
    .p-detail .lt .box .nav-tabs .nav-link.active{
        font-weight: 500;
    }
    .p-detail .main-detail{
        margin-left: 0;
    }
    .p-detail .main-detail h1{
        font-size: 45px;
    }
    .p-detail .main-detail .product-desc p{
        font-size: 17px;
    }
    .p-detail .main-detail .product-desc ul{
        padding: 0;
    } */
}

@media screen and (max-width: 991px) and (min-width: 768px){
    .abc .header{
        top: 0;
    }
    .product-details-page h1 {
        padding-top: 220px;
        font-size: 66px;
    }
    .product-details-page{
        padding-bottom: 150px;
    }
    .cash-me-windo p{
        font-size: 17px;
    }
    /* .p-detail .col-lg-4{
        width: 40%;
    }
    .p-detail .col-lg-8{
        width: 60%;
    }
    .p-detail .lt .box h3 {
        font-size: 23px;
    }
    .p-detail .lt .box .nav-link {
        font-size: 16px;
        padding: 5px;
        padding-left: 20px;
    }
    .p-detail .lt .box .nav-tabs .nav-link.active{
        font-weight: 500;
    }
    .p-detail .lt .box2 .info a{
        padding: 10px 15px;
    }
    .p-detail .lt .box2 .icon i{
        margin-right: 15px;
    }
    .p-detail .main-detail{
        margin-left: 0;
    }
    .p-detail .main-detail h1{
        font-size: 40px;
        margin-top: 15px;
    }
    .p-detail .main-detail .product-desc p{
        font-size: 17px;
    }
    .p-detail .main-detail .product-desc ul{
        padding: 0;
    }
    .p-detail .main-detail .over .featu .custom-hr {
        width: 37%;
    }
    .p-detail .main-detail .over .opt .custom-hr{
        width: 17%;
    }
    .detail-related h1{
        font-size: 45px;
    }
    .detail-related .col-lg-4{
        width: 50%;
    }
    .detail-related h4{
        font-size: 22px;
    } */
}

@media screen and (max-width: 767px) and (min-width: 426px){
    .abc .header{
        top: 0;
    }
    .product-details-page h1 {
        padding-top: 220px;
        font-size: 50px;
    }
    .product-details-page{
        padding-bottom: 150px;
    }
    .cash-me-windo p{
        font-size: 16px;
    }
    .cash-me-windo .features .wi-text .wi-te h6{
        margin-top: 0;
    }
    .cash-me-windo .faq-sec h3{
        font-size: 30px;
    }
    /* .p-detail .lt .box2{
        display: none;
    }
    .p-detail .main-detail {
        margin-left: 0px;
        margin-top: 30px;
    }
    .p-detail .main-detail h1{
        font-size: 45px;
    }
    .p-detail .main-detail .product-desc ul{
        padding: 0;
    }
    .p-detail .main-detail .over .featu .custom-hr {
        width: 30%;
    }
    .p-detail .main-detail .over .opt .custom-hr{
        width: 14%;
    }
    .detail-related h1{
        font-size: 45px;
    }
    
    .detail-related .col-lg-4{
        width: 50%;
    }
    .detail-related h4{
        font-size: 22px;
    } */
}

@media screen and (max-width: 425px) and (min-width: 376px){
    .abc .header{
        top: 0;
    }
    .product-details-page h1 {
        padding-top: 180px;
        font-size: 45px;
    }
    .product-details-page{
        padding-bottom: 100px;
    }
    .cash-me-windo {
        margin-top: 50px;
    }
    .cash-me-windo p{
        font-size: 16px;
    }
    .cash-me-windo .features .wi-text .wi-te h6{
        margin-top: 0;
    }
    .cash-me-windo .faq-sec h3{
        font-size: 30px;
    }
    /* .p-detail {
        margin-top: 40px;
    }
    .p-detail .lt .box2{
        display: none;
    }
    .p-detail .main-detail {
        margin-left: 0px;
        margin-top: 30px;
    }
    .p-detail .main-detail h1{
        font-size: 45px;
    }
    .p-detail .main-detail .product-desc ul{
        padding: 0;
    }
    .p-detail .main-detail .over .custom-hr {
        width: 32%;
    }
    .p-detail .main-detail .over .featu .custom-hr {
        width: 38%;
    }
    .p-detail .main-detail .over .opt .custom-hr{
        width: 18%;
    }
    .detail-related h1{
        font-size: 45px;
    }
    .detail-related {
        margin-top: 40px;
    }
    .product-contct .von{
        margin-top: 30px;
    }
    .product-contct .von a{
        padding: 12px 20px;
        font-size: 22px;
    } */

}

@media screen and (max-width: 375px) and (min-width: 300px){
    .abc .header{
        top: 0;
    }
    .product-details-page h1 {
        padding-top: 180px;
        font-size: 45px;
    }
    .product-details-page{
        padding-bottom: 100px;
    }
    .cash-me-windo {
        margin-top: 50px;
    }
    .cash-me-windo h2{
        font-size: 35px;
    }
    .cash-me-windo p{
        font-size: 16px;
    }
    .cash-me-windo .features .wi-text .wi-te h6{
        margin-top: 0;
    }
    .cash-me-windo .faq-sec h3{
        font-size: 30px;
    }
    /* .p-detail {
        margin-top: 40px;
    }
    .p-detail .lt .box h3{
        font-size: 28px;
    }
    .p-detail .lt .box .nav-link{
        font-size: 18px;
    }
    .p-detail .lt .box2{
        display: none;
    }
    .p-detail .main-detail {
        margin-left: 0px;
        margin-top: 30px;
    }
    .p-detail .main-detail h1{
        font-size: 45px;
    }
    .p-detail .main-detail .product-desc ul{
        padding: 0;
    }
    .p-detail .main-detail .over .custom-hr {
        width: 36%;
    }
    .p-detail .main-detail .over .featu .custom-hr {
        width: 43%;
    }
    .p-detail .main-detail .over .opt .custom-hr{
        width: 21%;
    }
    .detail-related h1{
        font-size: 45px;
    }
    .detail-related {
        margin-top: 40px;
    }
    .product-contct .von{
        margin-top: 30px;
    }
    .product-contct .von a{
        padding: 12px 20px;
        font-size: 22px;
    } */
}
