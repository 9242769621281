/* playfair font */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
.playfair {
    font-family: "Playfair Display", serif;
}
/* roboto font */
@import url('https://fonts.googleapis.com/css2?family=Mogra&family=Outfit:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.roboto{
    font-family: "Roboto", sans-serif;
}

/* popins font */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.poppins{
    font-family: "Poppins", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.outfit {
    font-family: "Outfit", sans-serif;
}

.footer{
    /* margin-top: 80px; */
    background-color: white;
    box-shadow: 0px 4px 61.9px 0px #00000040;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
}
.footer p{
    margin-top: 20px;
    color: #000;
    font-size: 17px;
    font-weight: 400;
}
.footer .footer-form button{
    background-color: #0A5096;
    color: #ffffff;
}
.footer h4{
    color: #000;
    font-size: 25px;
    font-weight: 500;
    margin-left: 25px;
}
.footer ul i{
    margin-right: 20px;
}
.footer ul li{
    list-style: none;
    color: #000;
    font-size: 17px;
    font-weight: 400;
    line-height: 45px;
}
.footer ul li a:hover{
    letter-spacing: 2px;
    transition: .3s ease-in-out;
}
.footer ul li a{
    text-decoration: none;
    color: #000;
}
.footer .f-add{
    display: flex;
    gap: 15px;
    margin-top: 15px;
}
.footer .f-add a{
    text-decoration: none;
    color: #000;
}
.footer .f-add i{
    color: #000;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}
.footer .f-add a{
    margin-top: 0;
}
.footer .f-add p{
    margin-top: 0;
}
.footer hr{
    color: #000;
    margin: 0;
    opacity: 1;
}
.footer .footer-icon{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.footer .footer-icon .icon a{
    margin-left: 20px;
}
.footer .footer-icon .bottom-footer-text p{
    margin-top: 0;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

@media screen and (max-width: 1024px) and (min-width: 992px){

}

@media screen and (max-width: 991px) and (min-width: 768px){
    .footer .col-lg-3{
        width: 50%;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 767px) and (min-width: 426px){
    .footer{
        padding: 25px;
    }
    .footer .col-lg-3{
        /* width: 50%; */
        margin-bottom: 30px;
    }
    .footer p{
        font-size: 16px;
    }
    .footer .footer-icon{
        display: block;
    }
    .footer .footer-icon .bottom-footer-text p{
        margin-top: 10px;
    }
}

@media screen and (max-width: 425px) and (min-width: 376px){
    .footer{
        padding: 25px;
    }
    footer .col-lg-3{
        margin-bottom: 30px;
    }
    .footer p{
        font-size: 16px;
    }
    .footer .footer-icon{
        display: block;
    }
    .footer .footer-icon .bottom-footer-text p{
        margin-top: 10px;
    }
}

@media screen and (max-width: 375px) and (min-width: 300px){
    .footer{
        padding: 05px;
    }
    footer .col-lg-3{
        margin-bottom: 50px;
    }
    .footer p{
        font-size: 16px;
    }
    .footer .footer-icon{
        display: block;
    }
    .footer .footer-icon .bottom-footer-text p{
        margin-top: 10px;
    }
}